.pub-grid {
    background-color: var(--white);

    display:flex;
    align-items:center;
    flex-wrap: wrap;
    width:100%;

    font-family:var(--font-sans);
    font-weight:300;
    font-size:var(--font-size-large);

}

.pub-grid__item {
    letter-spacing:-.05em;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    height: calc(var(--padding-s) * 15);
    
    font-family:var(--font-serif);
    font-style: italic;

    
    vertical-align:middle;
    

    span {
        margin-left: .12em;
        font-style: normal;
        font-family: var(--font-sans);

        display:table-cell;
        vertical-align:middle;
    }
    @-moz-document url-prefix() {
        span {
            margin-top: .2em;
        }
      }
    &:after {
        content: ".";
        color: var(--white);
        margin-left: .05em;
        font-family: var(--font-serif);
    }

    &--size-2 {
        flex:1 0 50%;
    }

    &--size-1 {
        flex:0 0 25%;
    }

    &--palette-1 {
        background-color:var(--gray-light);
        color:var(--white);
        span {
            color:var(--ochre);
        }
    }

    &--palette-1:hover {
        background-color:var(--blue);
        color:var(--ochre);

        span {
            color:var(--red);
        }
    }

    &--palette-2 {
        background-color:var(--ochre);
        color:var(--red);

        span {
            color:var(--blue);
        }
    }

    &--palette-2:hover {
        background-color:var(--red);
        color:var(--ochre);

        span {
            color:var(--blue);
        }
    }

    &--palette-3 {
        background-color:var(--blue);
        color:var(--ochre);

        span {
            color:var(--red);
        }
    }

    &--palette-3:hover {
        background-color:var(--gray-dark);
        color:var(--white);

        span {
            color:var(--blue);
        }
    }

    &--palette-4 {
        background-color:var(--ochre);
        color:var(--blue);

        span {
            color:var(--red);
        }
    }

    &--palette-4:hover {
        background-color:var(--red);
        color:var(--ochre);

        span {
            color:var(--blue);
        }
    }

    &--palette-5 {
        background-color:var(--gray-dark);
        color:var(--white);

        span {
            color:var(--blue);
        }
    }

    &--palette-5:hover {
        background-color:var(--blue);
        color:var(--ochre);

        span {
            color:var(--red);
        }
    }

    &--palette-6 {
        background-color:var(--red);
        color:var(--ochre);

        span {
            color:var(--blue);
        }
    }

    &--palette-6:hover {
        background-color:var(--blue);
        color:var(--ochre);

        span {
            color:var(--red);
        }
    }

    &--palette-7 {
        background-color:var(--gray-light);
        color:var(--white);

        span {
            color:var(--ochre);
        }
    }

    &--palette-7:hover {
        background-color:var(--ochre);
        color:var(--blue);

        span {
            color:var(--red);
        }
    }
}

@media (max-width: $smartphone) {
    .pub-grid {
        font-size: var(--font-size-mid);
    }

    .pub-grid__item {
        height: 100vw;

        &--size-1 {
            flex: 1 0 100%;
        }
    }

    .pub-grid {
        position:relative;
        height: 100vw;
    }
    .pub-grid__item {
        position:absolute;
        width:100vw;
        top:0;
        opacity: 0;
        &--palette-7 {
            animation: opacity 7s 6s infinite;
        }
        &--palette-6 {
            animation: opacity 7s 5s infinite;
        }
        &--palette-5 {
            animation: opacity 7s 4s infinite;
        }
        &--palette-4 {
            animation: opacity 7s 3s infinite;
        }
        &--palette-3 {
            animation: opacity 7s 2s infinite;
        }
        &--palette-2 {
            animation: opacity 7s 1s infinite;
        }
        &--palette-1 {
            animation: opacity 7s 0s infinite;
        }
    }
}

@keyframes opacity {
    0% {
        opacity:1;
    }
    14.99% {
        opacity:1;
    }
    15% {
        opacity:0;
    }
}