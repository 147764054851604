.block-evento {
    background-color:var(--blue);
    padding:var(--padding-s) var(--padding-m) var(--padding-m) var(--padding-m);

    .block-evento__content {
        display:flex;
        justify-content:space-between;
        align-items:flex-end;

        .text {
            margin-top:16.25%;
        }

        ul {
            font-size:var(--font-size-large);
            margin-top: var(--padding-m);
            width:100%;
            
            li {
                margin-top: var(--padding-s);
                text-align:center;
                letter-spacing:-.06em;
                font-weight:300;

                -webkit-clip-path: polygon(5% 0, 95% 0, 100% 30%, 100% 70%, 95% 100%, 5% 100%, 0% 70%, 0% 30%);
                clip-path: polygon(5% 0, 95% 0, 100% 30%, 100% 70%, 95% 100%, 5% 100%, 0% 70%, 0% 30%);
            }

            li.evento__palette--1 {
                padding: 0 1em;
                font-family:var(--font-serif);
                color:var(--blue);
                background-color:var(--gray-light);
            }
    
            li.evento__palette--2 {
                font-family:var(--font-sans);
                color:var(--blue);
                background-color:var(--ochre);
            }
    
            li.evento__palette--3 {
                font-family:var(--font-serif);
                color:var(--ochre);
                background-color:var(--red);
            }
    
            li.evento__palette--4 {
                font-family:var(--font-sans);
                color:var(--blue);
                background-color:var(--gray-dark);
            }
        }
        
    }
}

@media (max-width: $smartphone) {
    .block-evento {
        display: grid;
        justify-items:center;
        padding:var(--padding-m) var(--padding-s);
        .block-evento__content {
            flex-direction:column;
            .text {
                margin-top:0;
            }
            ul {
                font-size:var(--font-size-mid);
                    
                li , li.evento__palette--1 {
                    padding: 0em;
                    margin-top: .6em;
                }
            }
        }
    }
}
@media (max-width: $smartphoneSmall) {
    .block-evento {
        padding:var(--padding-s) var(--padding-xs);
        .block-evento__content {
            ul {
                margin-top: var(--padding-s);
                font-size: calc(var(--font-size-mid) - 5px);
            }
        }
    }
}
    