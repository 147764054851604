:root {
    --font-sans: 'UntitledSans', sans-serif;
    --font-sans-bold: 'UntitledSans', sans-serif;
    --font-serif: 'DomaineText', serif;

    --font-size-xxlarge: #{(330px / 16px) * 1rem};
    --font-size-xlarge: #{(88px / 16px) * 1rem};
    --font-size-large: #{(50px / 16px) * 1rem};
    --font-size-mid: #{(36px / 16px) * 1rem};
    --font-size-base: #{(18px / 16px) * 1rem};

    --font-size-h2: #{(88px / 16px) * 1rem};
    --font-size-h3: #{(40px / 16px) * 1rem};
    --font-size-grid: #{(30px / 16px) * 1rem};
    --font-size-small: #{(15px / 16px) * 1rem};
    --font-size-xsmall: #{(12px / 16px) * 1rem};

    --font-size-xxlarge--mobile: #{(200px / 16px) * 1rem};
    --font-size-xlarge--mobile: #{(100px / 16px) * 1rem};
    --font-size-large--mobile: #{(24px / 16px) * 1rem};
    --font-size-mid--mobile: --font-size-base;
    --font-size-polygon-mobile: #{(30px / 16px) * 1rem};

    --line-height-sans: .9em;
    --line-height-serif: -.05em;
    --letter-spacing: -0.06em;

    font-size: 1.1111vw;


    --font-size-xxlarge-mobile:89px;
    --font-size-xlarge-mobile: 100px;
    --font-size-large-mobile: 24px;
    --font-size-base-mobile: 12px;

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 300;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
  font-family: var(--font-sans-bold);
  font-weight: 500;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
  font-family: var(--font-serif);
  font-weight: 300;
  font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-serif);  }
}



.title {
  @include font-sans();

  font-size: var(--font-size-xxlarge);
  font-weight:300;
  letter-spacing:var(--letter-spacing);

  color: var(--white);
  text-align:center;

  i {
    @include font-sans-italic();
    color: var(--red);
  }
  &--carta {
    i {
    color: var(--blue);
    }
  }
}

.block__p {
  @include font-sans-italic();

  font-style: normal;
  width:70%;
  margin-bottom: var(--padding-m);
  font-size:var(--font-size-mid);
  line-height:1em;
  letter-spacing:-.05em;

  b,
  strong {
    color: var(--red);
    font-size:var(--font-size-mid);
    font-weight:300;
    letter-spacing:-.05em;
  }

  &--carrusel {
    width:75%;
    padding: var(--padding-m);
    color: var(--blue);
    b,
    strong {
      color:var(--ochre);
    }
  }

  &--evento {
    @include font-sans-italic();
    font-style: normal;
    margin: var(--padding-m) 0 0 0;
    width:calc(100% - var(--padding-m));
    color: var(--red);
    font-size:var(--font-size-mid);
    line-height:.9em;


    b,
    strong, 
    a {
      @include font-sans-italic();
      font-style: normal;
      color: var(--gray-light);
      position:relative;
      text-decoration:none;
    }
    a::after {
      content: " ";
      display: inline-block;
      position: absolute;
      top:93%;
      left: 0;
      transform:translate3d(0, 0, 0);
      width: 100%;
      height: 2px;
      background-color: var(--gray-light);
      transition: .5s .2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    a:hover {
      &::after {
        transform:translate3d(2%, 0, 0);
        width: 95%;
        height: 3px;
        transition: .5s .2s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    p:nth-child(2) {
      margin-top:var(--padding-s);
      background-color:var(--blue);
      padding-bottom: .05em;
      b,
      strong {
        color: var(--ochre);
        position: relative;
        overflow:hidden;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .title {
    font-size: var(--font-size-xxlarge-mobile);
    margin-bottom:var(--padding-m);
  }
  .block-evento .title {
    font-size: calc(var(--font-size-xxlarge-mobile) + 5px);
  }

  .block__p {
    margin: 0 0 var(--padding-l) 0;
    font-size:calc(var(--font-size-base)*1.1);
    line-height:1.1;
    width: 65%;
    b, 
    strong {
      font-size:calc(var(--font-size-base)*1.1);
    }

    &--carrusel {
      padding: var(--padding-s) 0 var(--padding-m)  var(--padding-s);
      margin-bottom:0;
    }
    &--evento {
      width:100%;
      font-size:calc(var(--font-size-base)*1.1);
      margin: 0;
      
      a::after {
        height: 1px;
      }
    }
  }
}
@media (max-width: $smartphoneMid) {
  .title {
    font-size: calc(var(--font-size-xxlarge-mobile) - 5px);
    margin-bottom:var(--padding-s);
    &--carta {
      font-size: calc(var(--font-size-xxlarge-mobile) - 10px);
    }
  }
  .block-evento .title {
    font-size: calc(var(--font-size-xxlarge-mobile) - 5px);
  }
  .block__p {
    width: 85%;
  }
}

@media (max-width: $smartphoneSmall) {
  :root {
    --font-size-xxlarge-mobile:78px;
  }
  .block-evento .title {
    font-size: var(--font-size-xxlarge-mobile);
    margin-bottom:var(--padding-s);
  }
  .title {
    margin-bottom:var(--padding-m);
    &--carta {
      font-size: calc(var(--font-size-xxlarge-mobile) - 5px);
    }
  }
  .block__p {
    width:85%;
    &--carrusel {
      padding: var(--padding-s) var(--padding-xs);
      width:100%;
    }
    &--evento {
      width:100%;
    }
  }
  .block-hero {
    .block__p {
      margin-bottom:var(--padding-s);
    }
  }
}