.block-carrusel {
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    color:var(--gray-dark);
    background-color: var(--red);
    height:calc(var(--padding-s) * 30);
    margin-bottom:-2.7em;

    .carrusel {
        overflow:hidden;
        width:100%;
        font-family:var(--font-sans);
        font-size:var(--font-size-xxlarge);
        font-weight:300;
        letter-spacing:-.1em;

        &__wrapper {
            display:flex;
            align-items:center;
            margin-left: -100vw;
        }

        .item {
            margin-right:.025em;
        }
        .item-serif {
            font-family:var(--font-serif);
        }
        .item:nth-child(4n - 15) {
            color:var(--ochre);
        }
        .item:nth-child(4n - 10) {
            color:var(--blue);
        }
        .item:nth-child(4n - 12) {
            color:var(--white);
        }
    }
}

@media (max-width: $smartphone) {
    .block-carrusel {
        flex-direction:column-reverse;
        height:calc(var(--padding-s) * 16);
        margin-bottom:0;

        .carrusel {
            font-size:var(--font-size-xxlarge--mobile);
        }
    }
}