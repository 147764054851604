:root {
  --white: #ffffff;
  --black: rgba(6,8,4,1);
  --grey: #999;

  --red:#E4332A;
  --blue:#2D2E81;
  --gray-dark:#858585;
  --gray-light:#9B9A9A;
  --ochre:#C9B99E;

  --primary: var(--black);
  --secondary: var(--white);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #E4332A;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--grey);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--black);
}

.palette-red {
    --primary: var(--black);
    --secondary: var(--color-error);
}
