#Scrollbar {

    opacity: 0;

    transform: rotate(90deg)!important;

    --height: #{(15px / 16px) * 1rem};

    position: fixed;
    @include z-index($z-index-scrollbar);

    width: 25%;
    height: var(--height);
    height: .5rem;
    bottom: 0;
    left: 81%;

    width: 10%;
    bottom: 11%;
    left: 94%;


    .track,
    .thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .track {
        background-color: var(--gray-light);
    }

    .thumb {
        transform-origin: 0 0;
        background-color: var(--red);
        transform: scale(0,1);
    }

    @include isTouch() {
        display: none;
    }
}
