.block-polygon-link,
.block-polygon-link--hero {
    display: inline-block;
    width: fit-content;

    .background {
        clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
        -webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
        background-color: var(--red);

        display: inline-block;
        width:21em;
        height:21em;
    }

    .text {
        position:absolute;

        width:100%;
        top:50%;
        left:50%;
        transform:translate3d(-50%, -50%, 0);

        font-family:var(--font-sans);
        font-weight:300;
        letter-spacing:-.05em;
        text-align:center;
        color:var(--white);
        font-size:var(--font-size-large);

        i {
            color:var(--blue);
            display:inline-block;
            font-family:var(--font-serif);
            font-style:italic;
        }
    }
    .text::after {
        content: " ";
        display: inline-block;
        position: absolute;
        bottom: .12em;
        top:150%;
        left: 50%;
        transform:translate3d(-50%, 0, 0);
        width: 75%;
        height: 2px;
        background-color: var(--white);
        transition: .5s .2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

}

.block-evento .block-polygon-link {
    position: absolute;
    transform:translate3d(0, -75%, 0);
    .background {
        background-color: var(--ochre);
    }
    .text {
        text-transform:uppercase;
        color: var(--blue);
    }
    .text::after {
        display:none;
    }
}

.block-polygon-link--carta {
    position: relative;
    margin-top: -3em;

    .text {
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        i , span {
            line-height:0;
        }
    }
}
.block-polygon-link--carta:hover  {
    .text::after {
        width: 70%;
        height: 3px;
        transition: .5s .2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}

.block-polygon-link--hero {
    position:absolute;
    bottom:0;
    left:calc(100% - 3.7em);

    .background {
        background-color: var(--white);

        display: inline-block;
        width:3.7em;
        height:3.7em;
    }
}


@media (max-width: $smartphone) {
    .block-polygon-link {
        grid-row:3;
        margin-top:var(--padding-m);

        .background {
            width:12em;
            height:12em;
        }
        .text {
            font-size:var(--font-size-polygon-mobile);
        }
        .text::after {
            height:1px;
        }
    }
    .block-evento .block-polygon-link {
        position: relative;
        transform:none;
    }
    .block-polygon-link--carta {
        position: normal;
        transform:none;
    }

    .block-polygon-link--hero {
        left:calc(100% - 4vw);
        .background {
            display: flex;
            align-items: flex-end;
            width:4vw;
            height:4vw;
        }
    }
}