.block-carta {
    background-color:var(--gray-light);
    padding:var(--padding-s) var(--padding-m) var(--padding-m) var(--padding-m);


    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
    .block-polygon-link {
        justify-self: end;
    }

    .cartas-holder {
        width:100%;
        display:flex;
        column-gap:var(--padding-s);
        justify-content:center;

        a {
            flex-grow:1;
        }
    }
}

@media (max-width: $smartphone) {
    .block-carta {
        padding:var(--padding-m) var(--padding-s);
        .block-polygon-link {
            justify-self: center;
        }
        .cartas-holder {
            flex-direction:column;
            row-gap: var(--padding-s);
        }
    }
}
@media (max-width: $smartphoneSmall) {
    .block-carta {
        padding:var(--padding-s) var(--padding-xs);
        .cartas-holder {
            grid-row-gap: var(--padding-xs);
            row-gap: var(--padding-xs);
        }
    }
}