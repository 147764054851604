.block-hero {
    padding: var(--padding-m);
    background-color:var(--ochre);
    color: var(--gray-light);
    font-family:var(--font-serif);
    letter-spacing: -1px;
}

.block-hero__text {
    display:flex;
    flex-direction: column;
    row-gap:calc(var(--padding-m)*4);
    position: relative;
    z-index: 3;

    .dock-logo {
        margin: 0 0 calc(var(--padding-s) * 5) 0;
        position:relative;
        display:flex;
        align-items:end;

        svg {
            width: 100%;
            height: auto;
        }
    }
}

.block-hero__ul {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap:var(--padding-m);
    column-gap:calc(var(--padding-m)*2);
    width:100%;
    margin: 0 auto;

    figure {
        margin: 0;
        position:relative;

        &.odd {
            top: var(--padding-xxl);
        }

        span {
            position:absolute;
            left:50%;
            top:50%;
            transform:translate3d(-50%, -50%, 0);
            text-transform: uppercase;
            font-family:var(--font-sans);
            font-size:var(--font-size-xlarge);
            color:var(--white);
            font-weight:300;
            letter-spacing:-.05em;
         }
    }
}

.hero-background {
    position:absolute;
    left:0;
    top:0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
   

    &__item {
        &--red {
            span {
                width:100%;
                padding-bottom:calc(var(--padding-m) * 6);
                left:0;
                top:0;
                position:absolute;
                background-color: var(--red);
                transform: rotate(9deg) translate3d(10%, -35%, 0);
            }
        }

        &--blue {
            span {
                clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
                -webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
                height:190vw;
                width:190vw;
                display:inline-block;
                position:absolute;
                transform:translate3d(-46%, -59%, 0) rotate(43deg);
                background-color: var(--blue);
            }
        }

        &--grey {
            span {
                clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
                -webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
                height:150vw;
                width:150vw;
                display:inline-block;
                position:absolute;
                left:100%;
                transform:translate3d(-45%, 11%, 0) rotate(-70deg);
                background-color: var(--gray-light);
            }

        }
    }

}


@media (max-width: $tabletLandscape) {
    .block-hero__ul {
        margin: 0 auto;
    }
}

@media (max-width: $smartphone) {
    .block-hero {
        padding: var(--padding-s);
        padding-bottom: var(--padding-m);
        .block-hero__text {
            display:flex;
            flex-direction:column-reverse;
            row-gap: var(--padding-xs);
            .dock-logo {
                margin: 0 0 var(--padding-xs) 0;
            }
        }
    }
    .block-hero__ul {
        row-gap:var(--padding-s);
        column-gap:var(--padding-s);
        margin: 0 auto;

        figure {
            &.odd {
                top: 40px;
            }
            span {
                font-size:var(--font-size-large-mobile);
            }
        }
    }

    .hero-background {
        background-color: transparent;

        &__item {

            &--blue {
                height:250vw;
                width:250vw;
                transform:translate3d(40px, -69%, 0) rotate(0deg)!important;
            }

            &--red {
                span {
                    padding-bottom:calc(var(--padding-s) * 6);
                }
                height:200vw;
                width:200vw;
                transform:translate3d(-40%, 0, 0)
            }
            &--grey {
                span{
                    height:225vw;
                    width:225vw;
                    transform:translate3d(-35%, 12%, 0) rotate(-70deg);
                }
            }
        }
    }
}
@media (max-width: $smartphoneSmall) {
    .block-hero {
        padding: var(--padding-xs);
        padding-bottom: var(--padding-m);
        .block-hero__text {
            row-gap: var(--padding-xs);
            .dock-logo {
                margin: 0 0 var(--padding-xs) 0;
            }
        }
    }
    .block-hero__ul {
        figure {
            span {
                font-size:var(--font-size-base);
            }
        }
    }
    .hero-background {
        background-color: transparent;

        &__item {
            &--blue {
                transform:translate3d(35px, -64%, 0) rotate(0deg)!important;
            }
        }
    }
}