#Footer {
    padding: var(--padding-s) var(--padding-m);
    background-color:var(--blue);
}

#Footer > nav {
    display: flex;
    align-items:flex-end;
    justify-content:center;

   a {
        @include font-sans(1.2);
        font-family:var(--font-serif);
        letter-spacing:-.05em;
        color: inherit;
    }
    
    a:hover {
      text-decoration:none;
    }

    .--location {
        display: flex;
        column-gap:var(--padding-s);

        margin:0 var(--padding-m) 0 calc(var(--padding-s)*3);

        color: var(--ochre);
        font-size: var(--font-size-mid);
        a {
            text-decoration:none;
            line-height:.9em;
        }
    }

    .--social , .--legal {
        display: flex;
        column-gap:var(--padding-xs);
        color: var(--gray-light);
        font-size: var(--font-size-base);
    }
}


@media (max-width: $smartphone) {
  #Footer {
    padding: var(--padding-s) var(--padding-xs);
  }
  #Footer > nav {
    flex-direction:column;
    align-items: flex-start;
    .--location {
        flex-direction:column;
        justify-content:left;
        margin:var(--padding-m) 0 var(--padding-xl) 0;
        a:first-child {
          margin-bottom:var(--padding-s);
        }
    }
    .--social {
        width: 100%;
        justify-content:space-between;
        font-size: calc(var(--font-size-base)*1.5);
    }
    .--legal {
        width: 100%;
        justify-content:space-between;
        column-gap:0;
        font-size: var(--font-size-small);
    }
  }
  #Footer .nav__group:nth-child(even) {
    flex-direction:column;
  }
}
@media (max-width: $smartphoneSmall) {
    #Footer {
      padding: var(--padding-s) var(--padding-xs);
    }
    #Footer > nav {
      .--location {
        margin: var(--padding-m) 0 var(--padding-xl) 0;
      }
      .--social {
          width: 100%;
          justify-content:space-between;
          font-size: calc(var(--font-size-base)*1.25);
      }
      .--legal {
          font-size: calc(var(--font-size-small)*.75);
      }
    }
  }
